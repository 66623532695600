import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Text from "common/src/components/Text";
import CopyrightWrapper from "./copyrightSection.style";

const CopyrightSection = () => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        social_profile {
          id
          icon
          link
        }
      }
    }
  `);

  return (
    <CopyrightWrapper className="copyright_section">
      <Text content={`Copyrights ${new Date().getFullYear()} Games by ROMA`} />
    </CopyrightWrapper>
  );
};

export default CopyrightSection;
