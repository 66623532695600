import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { lock } from 'react-icons-kit/entypo/lock';
import { Icon } from 'react-icons-kit';
import FeatureBlockWrapper, {
  IconWrapper,
  ContentWrapper,
  ButtonWrapper,
} from './featureBlock.style';

const Locked = () => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, backgroundColor: '#99999922', textAlign: 'end' }}>
      <Icon icon={lock} size={20} style={{ margin: 8, color: 'grey' }} />
    </div>
  )
}

const FeatureBlock = ({
  className,
  locked,
  icon,
  title,
  button,
  description,
  iconPosition,
  additionalContent,
  wrapperStyle,
  iconStyle,
  contentStyle,
  btnWrapperStyle,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['feature__block'];

  // Add icon position class
  if (iconPosition) {
    addAllClasses.push(`icon_${iconPosition}`);
  }

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // check icon value and add
  const Icon = icon && (
    <IconWrapper className="icon__wrapper" {...iconStyle}>
      {icon}
    </IconWrapper>
  );
  return (<div style={{ position: 'relative' }}>

    <FeatureBlockWrapper
      className={addAllClasses.join(' ')}
      {...wrapperStyle}
      {...props}
    >
      {Icon}

      {title || description || button ? (
        <Fragment>
          <ContentWrapper className="content__wrapper" {...contentStyle}>
            {title}
            {description}
            {button && (
              <ButtonWrapper className="button__wrapper" {...btnWrapperStyle}>
                {button}
              </ButtonWrapper>
            )}
          </ContentWrapper>
          {additionalContent}
        </Fragment>
      ) : (
        ''
      )}
    </FeatureBlockWrapper>
      {locked && <Locked />}
  </div>
  );
};

FeatureBlock.propTypes = {
  /** ClassName of the FeatureBlock */
  className: PropTypes.string,
  locked: PropTypes.bool,

  /** title prop contain a react component. You can use our Heading component from reusecore */
  title: PropTypes.element,

  /** description prop contain a react component. You can use our Text component from reusecore */
  description: PropTypes.element,

  /** button prop contain a react component. You can use our Button component from reusecore */
  button: PropTypes.element,

  /** Set icon position of the FeatureBlock */
  iconPosition: PropTypes.oneOf(['top', 'left', 'right']),

  /** wrapperStyle prop contain these style system props:  display, flexWrap, width, height, alignItems,
   * justifyContent, position, overflow, space, color, borders, borderColor, boxShadow and borderRadius. */
  wrapperStyle: PropTypes.object,

  /** iconStyle prop contain these style system props: display, width, height, alignItems, justifyContent,
   * position, space, fontSize, color, borders, overflow, borderColor, boxShadow and borderRadius. */
  iconStyle: PropTypes.object,

  /** contentStyle prop contain these style system props: width, textAlign and space. */
  contentStyle: PropTypes.object,

  /** btnWrapperStyle prop contain these style system props: display, space, alignItems,
   * flexDirection and justifyContent. */
  btnWrapperStyle: PropTypes.object,
};

FeatureBlock.defaultProps = {
  iconPosition: 'top',
  locked: false,
};

export default FeatureBlock;
